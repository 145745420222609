import { Button, Form, Select, InputNumber, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import { finishDebateProcess } from "helpers/backend_helper";
import { useCallback, useContext } from "react";
import { AdminContext } from "context/adminContext";
import { useRequest } from "ahooks";
import { NotificationContext } from "context/notificationContext";

import styles from "./FinishDebateForm.module.css";

interface IFinishDebateFormProps {
  debateId: string;
  refreshData: () => void;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  insuranceDeposit: number;
  insuranceType: string;
}

export const FinishDebateForm = ({
  debateId,
  refreshData,
  setIsOpened,
  insuranceDeposit,
  insuranceType,
}: IFinishDebateFormProps) => {
  const [form] = Form.useForm();
  const { localCountry } = useContext(AdminContext);
  const { openNotificationWithIcon } = useContext(NotificationContext);

  const { loading, run: finish } = useRequest(finishDebateProcess, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      refreshData();
      setIsOpened(false);
    },
  });

  const onFinish = useCallback(
    async (values) => {
      finish(debateId, localCountry.iso, values);
    },
    [debateId, finish, localCountry],
  );

  return (
    <Form layout="horizontal" form={form} onFinish={onFinish} rootClassName={styles.finishDebateForm}>
      <Row gutter={5}>
        <Form.Item
          name="productStatus"
          label="Product status"
          rules={[
            {
              required: true,
              message: "type is required",
            },
          ]}
        >
          <Select
            placeholder="status"
            style={{ width: "200px" }}
            options={[
              { label: "Published", value: "Published" },
              { label: "Stop", value: "STOP" },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="decision"
          label="Decision"
          rules={[
            {
              required: true,
              message: "type is required",
            },
          ]}
        >
          <Select
            placeholder="decision"
            style={{ width: "200px" }}
            options={[
              { label: "Fine payment", value: "FINE_PAYMENT" },
              { label: "Natural wear", value: "NATURAL_WEAR" },
              { label: "Not confirme", value: "NOT_CONFIRMED" },
            ]}
          />
        </Form.Item>
        <Form.Item name="damageRefundAmount" label="Damage refund amount" initialValue={0}>
          <InputNumber min={0} max={insuranceType === "DEPOSIT" ? insuranceDeposit : undefined} defaultValue={0} />
        </Form.Item>
      </Row>
      <Form.Item name="comment" label="Comment">
        <TextArea rows={2} />
      </Form.Item>
      <div>
        <Button style={{ marginRight: 20 }} type="primary" htmlType="submit" loading={loading}>
          Finish debate
        </Button>
      </div>
    </Form>
  );
};
