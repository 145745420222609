import { useContext, useEffect, useMemo, useState } from "react";
import { Button, Card, Form, Input, Modal, Select } from "antd";
import { useRequest } from "ahooks";
import { geyOptionById, updateOption } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import { AdminContext } from "context/adminContext";
import LanguageForm from "../LanguageNameForm";
import DynamicOptions from "../DynamicItems";
import type { Language } from "../../Languages";

import styles from "./index.module.css";

type Props = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  refresh: () => void;
  optionId: number;
  languages: Language[];
  numericRange?: { max: number; min: number };
};

const options = [
  { label: "Numeric", value: "Numeric" },
  { label: "String", value: "String" },
  { label: "Select", value: "Select" },
  { label: "MultiSelect", value: "MultiSelect" },
];

const EditOptionModal = ({ refresh, setIsModalOpen, isModalOpen, languages, optionId }: Props) => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(false);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);
  const {
    loading: getOptionLoading,
    data: option,
    run: getOption,
    refresh: refreshOption,
  } = useRequest(() => geyOptionById(optionId, localCountry?.iso), {
    manual: true,
    ready: !!localCountry,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });
  const { loading: createLoading, run: update } = useRequest(updateOption, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
      setDisabled(false);
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "option was updated");
      refresh();
      refreshOption();
      setDisabled(false);
      setIsModalOpen(false);
    },
  });
  const selectorValues = useMemo(
    () => option?.optionSelect?.values.map((_, i) => ({ key: i + 1, name: i + 1 })),
    [option?.optionSelect?.values],
  );

  const onFinish = (values) => {
    setDisabled(true);
    const translations = languages?.map((language) => {
      const nameKey = `option name${language.code}`;
      return { name: values[nameKey], languageCode: language.code };
    });
    const select = languages?.map((language) => {
      const nameKey = `option name${language.code}`;
      return { name: values[nameKey], languageCode: language.code };
    });
    const selectValues = [];
    if (values.values) {
      Object.entries(values.values).forEach((value: [string, string], i) => {
        const language = languages?.find((lang) => value[0].endsWith(lang.code));
        const key = +value[0].split(" ").at(-2) - 1;
        if (!selectValues[key]) {
          const id = option?.optionSelect?.values[Math.floor(i / 2)]?.id;
          selectValues[key] = { translations: [{ value: value[1], languageCode: language.code }] };
          selectValues[key].id = id;
        } else {
          selectValues[key].translations.push({ value: value[1], languageCode: language.code });
        }
      });
    }

    const type: string = values?.type;

    const groupData = {
      type,
      translations,
      select:
        type === "Numeric" || type === "String"
          ? undefined
          : { translations: select, values: selectValues.filter(Boolean) },
      productGroupId: values.group,
      numericRange: type === "Numeric" ? { min: +values.min, max: +values.max } : undefined,
    };

    update(optionId, groupData, localCountry?.iso);
  };

  useEffect(() => {
    if (option) {
      form.resetFields();
      form.setFieldValue("type", option.type);
      form.setFieldValue("categories", "");
      languages?.forEach((language) => {
        const nameKey = `option name${language.code}`;
        const nameValue = option.translations.find((opt) => opt.languageCode === language.code)?.name;
        option.optionSelect?.values?.map((val, i) => {
          languages?.forEach((lang) => {
            const selKey = `selector value ${i + 1} ${lang.code}`;
            const selValue = val.translations.find((opt) => opt.languageCode === lang.code)?.value;
            form.setFieldValue(["values", selKey], selValue);
          });
        });
        if (option?.type === "Numeric") {
          form.setFieldValue("min", option.min);
          form.setFieldValue("max", option.max);
        }

        form.setFieldValue(nameKey, nameValue);
      });
    }
  }, [form, languages, option]);

  useEffect(() => {
    if (optionId) {
      getOption();
    }
  }, [getOption, optionId]);

  return (
    <Modal
      className={styles.modal}
      title="Update option"
      open={isModalOpen}
      footer={null}
      onCancel={() => setIsModalOpen(false)}
    >
      <div className={styles.container}>
        <Form layout="vertical" onFinish={onFinish} disabled={disabled} form={form} style={{ maxWidth: "600px" }}>
          <Card size="small" title="Option name" className={styles.card}>
            {languages?.map((language) => <LanguageForm key={language.id} language={language} name="option name" />)}
          </Card>
          <Card size="small" title="Type" className={styles.card} loading={getOptionLoading}>
            <Form.Item
              name="type"
              rules={[
                {
                  required: true,
                  message: "type is required",
                },
              ]}
            >
              <Select placeholder="type" options={options} disabled />
            </Form.Item>
          </Card>

          {selectorValues?.length ? <DynamicOptions languages={languages} initValues={selectorValues} /> : null}
          {option?.type === "Numeric" ? (
            <Card size="small" title="Range" className={styles.card}>
              <Form.Item
                name="min"
                rules={[
                  {
                    required: true,
                    message: "min is required",
                  },
                ]}
              >
                <Input placeholder="min" type="number" />
              </Form.Item>
              <Form.Item
                name="max"
                rules={[
                  {
                    required: true,
                    message: "max is required",
                  },
                ]}
              >
                <Input placeholder="max" type="number" />
              </Form.Item>
            </Card>
          ) : null}
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={createLoading} style={{ marginTop: "10px" }}>
              Edit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EditOptionModal;
