import { Chat } from "pages/Main/Components/Chat";

import styles from "./Details.module.css";
import { useArbitrSocket } from "./hooks/useArbitrSocket";
import { useEffect } from "react";

export const DetailsModalChat = ({ chatId }) => {
  const { joinChatArbitr, sendMessageArbitr, messages, historyLoading } = useArbitrSocket(chatId);

  useEffect(() => {
    joinChatArbitr(chatId);
  }, [chatId, joinChatArbitr]);

  return (
    <div className={`${styles.col} ${styles.chatSection}`}>
      <Chat
        chatId={chatId}
        messages={messages.messages}
        sendSocketMessage={sendMessageArbitr}
        loading={historyLoading}
      />
    </div>
  );
};
