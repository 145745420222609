import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useRequest } from "ahooks";
import { getDebate, getDebateDetails } from "helpers/backend_helper";
import { AdminContext } from "context/adminContext";
import { NotificationContext } from "context/notificationContext";
import { Divider, Table, Select, Button, Checkbox, Pagination } from "antd";
import { columns, formatDate } from "./utils";
import { Pagination as IPagination } from "../Options";
import { DetailsModal } from "./DetailsModal";

import styles from "./Debate.module.css";

const REQUESTS_PER_PAGE = 7;

export const Debate = () => {
  const { localCountry } = useContext(AdminContext);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const [pagination, setPagination] = useState<IPagination>({ take: REQUESTS_PER_PAGE, skip: 0, total: 0 });
  const [status, setStatus] = useState("");
  const [debateId, setDebateId] = useState(null);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [myDebate, setMyDebate] = useState(false);

  useEffect(() => {
    setPagination({ take: REQUESTS_PER_PAGE, skip: 0, total: 0 });
  }, [status, myDebate]);

  const onPaginationChange = useCallback((page: number) => {
    setPagination((prev) => ({ ...prev, skip: page * REQUESTS_PER_PAGE - REQUESTS_PER_PAGE }));
  }, []);

  const { loading, data, refresh } = useRequest(() => getDebate(localCountry?.iso, pagination, myDebate, status), {
    refreshDeps: [localCountry, status, myDebate, pagination],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });

  const {
    loading: detailsLoading,
    data: debateDetails,
    refresh: refreshDetails,
  } = useRequest(() => getDebateDetails(debateId, localCountry?.iso), {
    ready: !!debateId,
    refreshDeps: [debateId],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });

  const onCheckboxChange = (e) => setMyDebate(e.target.checked);

  const refreshData = useCallback(() => {
    refresh();
    refreshDetails();
  }, [refresh, refreshDetails]);

  const source = useMemo(
    () =>
      data?.debateRequests?.map((i) => ({
        ...i,
        createdAt: formatDate(i.createdAt),
        updatedAt: formatDate(i.updatedAt),
        resolvedAt: formatDate(i.resolvedAt),
        actions: (
          <div className={styles.buttonWrapper}>
            <Button
              style={{ marginRight: 20 }}
              type="primary"
              onClick={() => {
                setDebateId(i.id);
                setIsModalOpened(true);
              }}
            >
              Details
            </Button>
          </div>
        ),
      })) || [],
    [data],
  );

  return (
    <div>
      <Divider orientation="left" className={styles.divider}>
        Products
      </Divider>
      <div className={styles.wrapper}>
        <Select
          loading={loading}
          placeholder="status"
          style={{ width: "200px" }}
          options={[
            { label: "All", value: "" },
            { label: "Wait", value: "WAIT" },
            { label: "In progress", value: "IN_PROGRESS" },
            { label: "Resolved", value: "RESOLVED" },
          ]}
          onChange={(value) => setStatus(value)}
        />
        <Checkbox onChange={onCheckboxChange} rootClassName={styles.meMode}>
          My debate
        </Checkbox>
        <div className={styles.pagination}>
          <Pagination
            total={data?.count}
            current={pagination.skip / REQUESTS_PER_PAGE + 1}
            onChange={onPaginationChange}
            defaultPageSize={REQUESTS_PER_PAGE}
          />
        </div>
      </div>
      <Table loading={loading} dataSource={source} columns={columns} pagination={false} />

      <DetailsModal
        isOpened={isModalOpened}
        setIsOpened={setIsModalOpened}
        data={debateDetails}
        loading={detailsLoading}
        refreshData={refreshData}
      />
    </div>
  );
};
