import { useRequest } from "ahooks";
import { AdminContext } from "context/adminContext";
import { NotificationContext } from "context/notificationContext";
import { SocketContext } from "context/socketContext";
import { getArbitrChat } from "helpers/backend_helper";
import { useCallback, useContext, useEffect } from "react";

export const useArbitrSocket = (chatId: string) => {
  const { socket, chatIdRef } = useContext(SocketContext);
  const { accountData, localCountry, setChats, chats } = useContext(AdminContext);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const countryIsoCode = localCountry?.iso.toUpperCase();

  chatIdRef.current = chatId;

  const { loading: historyLoading } = useRequest(() => getArbitrChat(localCountry.iso.toUpperCase(), chatId), {
    ready: !!chatId,
    onError: (error: any) => {
      if (error?.response.data.message) {
        openNotificationWithIcon("error", error.response.data.message);
      } else {
        openNotificationWithIcon("error", error.message);
      }
    },
    onSuccess: (data) => {
      if (data?.messages) {
        setChats((prev) => {
          return { ...prev, debateChat: data };
        });
      }
    },
  });

  const joinChatArbitr = useCallback(
    (chatId: string) => {
      socket.emit("joinChatArbitr", { chatId, countryIsoCode });
      socket.emit("joinClientMessagesSubscribe", {
        countryIsoCode,
        chatId,
      });
    },
    [socket, countryIsoCode],
  );

  const sendMessageArbitr = (content: string, chatId: string) => {
    socket.emit("sendMessage", { chatId, content, countryIsoCode, createByAdmin: true });

    setChats((prev) => {
      const debateChat = prev.debateChat;
      prev.debateChat.messages.unshift({
        createdAt: new Date().toISOString(),
        chatId,
        content,
        admin: accountData.id,
      });

      return { ...prev, debateChat };
    });
  };

  useEffect(() => {
    return () => {
      setChats((prev) => ({ ...prev, debateChat: { cursor: 0, messages: [] } }));
    };
  }, []);

  return { joinChatArbitr, sendMessageArbitr, messages: chats.debateChat, historyLoading };
};
