import { createContext, useContext, useEffect, useState } from "react";
import TonConnect from "@tonconnect/sdk";
import { TonStorage } from "helpers/ton-storage";
import { createWallet } from "helpers/backend_helper";
import { AdminContext } from "./adminContext";

type ContextProps = {
  connector?: any;
  storage?: any;
  connectWallet?: (success: any, error: any) => string;
  universalLink?: string;
};

const manifestUrl = process.env.NODE_ENV === "production" ? "https://admin.c2c.goldapp.ru/" : "http://localhost:3000/";

const WALLET_CONNECTION_SOURCE = {
  universalLink: "https://app.tonkeeper.com/ton-connect",
  bridgeUrl: "https://bridge.tonapi.io/bridge",
};

export const TonContext = createContext({} as ContextProps);

export const TonProvider = ({ children }: { children: JSX.Element }) => {
  const [connector, setConnector] = useState(null);
  const { refreshAccountData, localCountry } = useContext(AdminContext);
  const [storage, setStorage] = useState(null);
  const [universalLink, setUniversalLink] = useState(null);

  const connectWallet = (success, error) => {
    setStorage(new TonStorage(null));
    const newConnector = new TonConnect({
      manifestUrl: manifestUrl + "tonconnect-manifest.json",
      storage,
    });
    setUniversalLink(newConnector.connect(WALLET_CONNECTION_SOURCE));
    newConnector.onStatusChange((wallet) => {
      if (!wallet) {
        error("nowalleterr");
        return;
      }
      success(newConnector, storage);
    });

    setConnector(newConnector);
    return universalLink;
  };
  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    connectWallet(
      async (conn) => {
        const { _wallet, provider } = conn;
        try {
          const jsonString = JSON.stringify({ connector: { _wallet, provider } });
          await createWallet(btoa(jsonString), localCountry.iso);
          refreshAccountData(localCountry.iso);
        } catch (err) {
          if (signal.aborted) {
            console.log("Operation aborted");
          }
          console.error("Failed to create wallet:", err);
        }
      },
      (error) => console.log(error),
    );

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <TonContext.Provider value={{ connector, storage, connectWallet, universalLink }}>{children}</TonContext.Provider>
  );
};
