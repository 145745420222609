import { Button, Popconfirm } from "antd";
import { assignRole, activateUser, approveUser, deleteUser } from "helpers/backend_helper";
import { useRequest } from "ahooks";
import { useContext, useState } from "react";
import { NotificationContext } from "context/notificationContext";
import UserModal from "./Modal";
import { UsersContext, type User } from "context/usersContext";
import { AdminContext } from "context/adminContext";

import styles from "./index.module.css";

type Props = {
  user: User;
};

const ActionButtons = ({ user }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { localCountry } = useContext(AdminContext);
  const { getUsersList } = useContext(UsersContext);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { loading, run: approve } = useRequest(approveUser, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "user updated");
    },
  });
  const { loading: deleteUserLoading, run: deleteUserHandler } = useRequest(deleteUser, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "user was deleted");
      getUsersList(localCountry.iso);
    },
  });
  const { loading: activateUserLoading, run: activateUserHandler } = useRequest(activateUser, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "user was activated");
      getUsersList(localCountry.iso);
    },
  });

  const { loading: assignRoleLoading, run: assignRoleHandler } = useRequest(assignRole, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "user was assigned a new role");
      getUsersList(localCountry.iso);
    },
  });

  if (!user) return null;

  const allowArbitrAssign =
    user.active &&
    user.userRoles[0].approved &&
    !user?.userRoles?.find((el) => el.role === "ARBITR" || el.role === "SUPERADMIN");

  return (
    <div className={styles.buttons}>
      <Button
        loading={loading}
        size="large"
        className={styles.button}
        ghost
        danger
        style={{ fontWeight: 400 }}
        onClick={() => setIsModalOpen(true)}
      >
        edit
      </Button>
      <UserModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} user={user} />
      <Popconfirm
        title={`Delete ${user.firstName} ${user.lastName}`}
        description="Are you sure to delete this user?"
        onConfirm={() => deleteUserHandler(user.id, localCountry.iso)}
        okText="Yes"
        cancelText="No"
      >
        <Button
          loading={deleteUserLoading}
          className={styles.button}
          type="primary"
          danger
          style={{ fontWeight: 400 }}
          size="large"
        >
          delete
        </Button>
      </Popconfirm>
      {!user.active ? (
        <Popconfirm
          title={`Activate ${user.firstName} ${user.lastName}`}
          description="Are you sure to activate this user?"
          onConfirm={() => activateUserHandler(user.id, localCountry.iso)}
          okText="Yes"
          cancelText="No"
        >
          <Button
            loading={activateUserLoading}
            className={styles.activateButton}
            type="primary"
            style={{ fontWeight: 400 }}
            size="large"
          >
            activate
          </Button>
        </Popconfirm>
      ) : null}

      {user.userRoles[0].approved ? null : (
        <Button
          size="large"
          loading={loading}
          className={styles.button}
          onClick={() => approve(user.id, user.userRoles[0]?.role, localCountry.iso)}
          type="primary"
          style={{ fontWeight: 400 }}
        >
          confirm admin
        </Button>
      )}
      {user.userRoles[0].approved && allowArbitrAssign && (
        <Button
          size="large"
          loading={assignRoleLoading}
          className={styles.button}
          onClick={() => assignRoleHandler(user.id, "ARBITR", localCountry.iso)}
          type="primary"
          style={{ fontWeight: 400 }}
        >
          appoint as arbitr
        </Button>
      )}
    </div>
  );
};

export default ActionButtons;
