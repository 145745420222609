import React from "react";
import { Routes, Route } from "react-router-dom";
import { Layout, ConfigProvider } from "antd";
import "antd/dist/reset.css";
import "./index.module.css";
import MainPage from "pages/Main";
import { AdminProvider } from "context/adminContext";
import LoginPage from "pages/Login";
import AuthGuard from "helpers/AuthGuard";
import { TonProvider } from "context/tonContext";
import { UsersProvider } from "context/usersContext";
import { SocketProvider } from "context/socketContext";

import styles from "./index.module.css";
import "./style.css";
const App: React.FC = () => (
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: "#ffcc4a",
        colorInfo: "#ffcc4a",
        fontFamily: "Mont",
      },
    }}
  >
    <AdminProvider>
      <SocketProvider>
        <UsersProvider>
          <TonProvider>
            <Layout className={styles.layout}>
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route
                  path="*"
                  element={
                    <AuthGuard>
                      <MainPage />
                    </AuthGuard>
                  }
                />
              </Routes>
            </Layout>
          </TonProvider>
        </UsersProvider>
      </SocketProvider>
    </AdminProvider>
  </ConfigProvider>
);

export default App;
