import { useContext, useMemo, useState } from "react";
import { Button, Divider, Select, Table } from "antd";
import { useRequest } from "ahooks";
import { getGroups, getProducts } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import { type Category } from "../Categories";
import { AdminContext } from "context/adminContext";
import ActionModal from "./ActionModal";
import Search from "antd/es/input/Search";

import styles from "./index.module.css";

export type Group = {
  name: string;
  id: number;
  description: string;
  productCategories: Category[];
};

const columns = [
  {
    title: "User name",
    dataIndex: "userName",
    key: "userName",
  },
  {
    title: <div className={styles.renter}>Status</div>,
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Ad name",
    dataIndex: "adName",
    key: "adName",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: <div className={styles.price}>Product group</div>,
    dataIndex: "productGroup",
    key: "productGroup",
  },
  {
    title: " ",
    dataIndex: "actions",
    key: "actions",
  },
];

const Products = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedAd, setSelectedAd] = useState<Group>(null);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);
  const {
    loading: getLoading,
    data: products,
    run,
    refresh,
  } = useRequest((id) => getProducts(localCountry?.iso, selectedStatus, id), {
    refreshDeps: [selectedStatus, localCountry],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });
  const { loading: getGroupsLoading, data: groups } = useRequest(getGroups, {
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });

  const source = useMemo(
    () =>
      products?.map((el, i) => ({
        ...el,
        key: i,
        productGroup: groups?.find((g) => g.id === el.productGroupId)?.name || "",
        userName: el.userName,
        adName: el.name,
        description: el.description,
        actions: (
          <div className={styles.buttonWrapper}>
            <Button
              style={{ marginRight: 20 }}
              type="primary"
              onClick={() => {
                setSelectedAd(el);
                setIsModalOpen(true);
              }}
            >
              Details
            </Button>
          </div>
        ),
      })) || [],
    [groups, products],
  );

  return (
    <div className={styles.container}>
      <Divider orientation="left" className={styles.divider}>
        Products
      </Divider>
      <div className={styles.wrapper}>
        <Select
          loading={getGroupsLoading}
          placeholder="group"
          style={{ width: "200px" }}
          options={[
            { label: "All", value: "" },
            { label: "Draft", value: "Draft" },
            { label: "Pending", value: "Pending" },
            { label: "Published", value: "Published" },
            { label: "Corrections", value: "Corrections" },
            { label: "Archive", value: "Archive" },
          ]}
          onChange={(value) => setSelectedStatus(value)}
        />
        <Search className={styles.search} placeholder={"id"} allowClear style={{ width: 304 }} onSearch={run} />
      </div>
      <Table
        loading={getLoading || getGroupsLoading}
        dataSource={source}
        columns={columns}
        className={styles.table}
        pagination={false}
      />

      <ActionModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} refresh={refresh} ad={selectedAd} />
    </div>
  );
};

export default Products;
