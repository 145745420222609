import { useRequest } from "ahooks";
import { createContext, useContext, useEffect, useState } from "react";
import { getAccount, getCountry, getSupportChat } from "helpers/backend_helper";
import { User } from "./usersContext";
import { Country } from "pages/Main/Components/AdminPanel/Countries";
import { NotificationContext } from "./notificationContext";

export type ContextProps = {
  isAdmin: boolean;
  loading: boolean;
  setIsAdmin: React.Dispatch<React.SetStateAction<boolean>>;
  isAuthorized: boolean;
  setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
  accountData: User;
  refreshAccountData: (country: string) => void;
  localCountry: Country;
  setLocalCountry: React.Dispatch<React.SetStateAction<Country>>;
  countries: Country[];
  refreshCountries: () => void;
  chats: Chats;
  setChats: React.Dispatch<React.SetStateAction<Chats>>;
  getChatHistory: (id: string) => void;
  getHistoryLoading: boolean;
};

export enum ChatType {
  RENT = "RENT",
  PERSONAL = "PERSONAL",
  SUPPORT = "SUPPORT",
}

export type Message = {
  authorType?: "CLIENT" | "SUPPORT";
  clientId?: string;
  admin?: string;
  author?: {
    firstName: string;
    id: string;
    lastName: string;
  };
  authorId?: string;
  chatId: string;
  content: string;
  createdAt: string;
  id?: number;
  read?: boolean;
  updatedAt?: string;
  supportId?: null | string;
  chatType?: ChatType;
};

type Chat = { cursor: number; messages: Message[] };

export type Chats = {
  supportChat: Chat;
  debateChat: Chat;
};

export const AdminContext = createContext({} as ContextProps);

const initialMessages = { cursor: 0, messages: [] };

export const AdminProvider = ({ children }: { children: JSX.Element }) => {
  const [isAdmin, setIsAdmin] = useState(true);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const [isAuthorized, setIsAuthorized] = useState(
    !!localStorage.getItem("accessToken") || !!sessionStorage.getItem("accessToken"),
  );
  const { data: countries, refresh: refreshCountries } = useRequest(getCountry, { ready: isAuthorized });
  const [localCountry, setLocalCountry] = useState<Country>(JSON.parse(localStorage.getItem("country")));
  const [chats, setChats] = useState<Chats>({ supportChat: initialMessages, debateChat: initialMessages } as Chats);

  const { loading: getHistoryLoading, run: getChatHistory } = useRequest(
    (id: string) => getSupportChat(localCountry.iso.toUpperCase(), id),
    {
      manual: true,
      onError: (error: any) => {
        if (error?.response.data.message) {
          openNotificationWithIcon("error", error.response.data.message);
        } else {
          openNotificationWithIcon("error", error.message);
        }
      },
      onSuccess: (data) => {
        if (data?.messages) {
          setChats((prev) => {
            return { ...prev, supportChat: data };
          });
        }
      },
    },
  );

  const {
    loading,
    data: accountData,
    run: refreshAccountData,
  } = useRequest(getAccount, {
    ready: isAuthorized,
  });

  useEffect(() => {
    if (accountData?.id) {
      setIsAuthorized(true);
    }
  }, [accountData]);

  useEffect(() => {
    if (countries) {
      const local = localStorage.getItem("country");
      if (!local) {
        localStorage.setItem("country", JSON.stringify(countries[0]));
        setLocalCountry(countries[0]);
      } else {
        const country = countries.find((c) => c?.iso === localCountry?.iso);
        localStorage.setItem("country", JSON.stringify(country));
        setLocalCountry(country);
      }
    }
  }, [countries, localCountry]);

  return (
    <AdminContext.Provider
      value={{
        isAdmin,
        setIsAdmin,
        isAuthorized,
        setIsAuthorized,
        accountData,
        loading,
        refreshAccountData,
        localCountry,
        setLocalCountry,
        countries,
        refreshCountries,
        chats,
        setChats,
        getChatHistory,
        getHistoryLoading,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};
