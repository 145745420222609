import { Input, Spin } from "antd";
import { useState } from "react";
import { Message } from "context/adminContext";
import dayjs from "dayjs";

import styles from "./Chat.module.css";

type Props = {
  chatId: string;
  sendSocketMessage: (content: string, chatId: string) => void;
  loading: boolean;
  messages: Message[];
};

export const Chat = ({ chatId, messages, sendSocketMessage, loading }: Props) => {
  const [textMessage, setTextMessage] = useState("");
  const sendTextHandler = () => {
    sendSocketMessage(textMessage, chatId);
    setTextMessage("");
  };

  return (
    <div className={styles.chatWrapper}>
      {loading ? (
        <Spin></Spin>
      ) : (
        <>
          <div className={styles.chat}>
            {messages?.map((message) => {
              if (message.authorType === "CLIENT" || message.author) {
                return (
                  <div key={message.id} className={styles.myMessages}>
                    {message.author && (
                      <div
                        className={styles.messageDate}
                      >{`${message.author.firstName} ${message.author.lastName}`}</div>
                    )}
                    <div className={styles.messageDate}>{dayjs(message.createdAt).format("MMMM D, hh:mm A")}</div>
                    <div className={styles.message}>{message.content}</div>
                  </div>
                );
              } else {
                return (
                  <div key={message.id} className={styles.otherMessages}>
                    <div className={styles.messageDate}>{dayjs(message.createdAt).format("MMMM D, hh:mm A")}</div>
                    <div className={styles.message}>{message.content}</div>
                  </div>
                );
              }
            })}
          </div>
          <div className={styles.inputBox}>
            <Input
              className={styles.input}
              value={textMessage}
              onChange={(e) => setTextMessage(e.target.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  sendTextHandler();
                }
              }}
            />
            <button className={styles.sendButton} onClick={sendTextHandler} />
          </div>
        </>
      )}
    </div>
  );
};
