import { useContext, useEffect, useMemo, useState } from "react";
import { Button, Divider, Form, Pagination, Popconfirm, Select, Table } from "antd";
import { useRequest } from "ahooks";
import { deleteOption, getGroupOptions, getGroups } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import type { Category } from "../Categories";
import NewOption from "./NewOption";
import { AdminContext } from "context/adminContext";
import EditOptionModal from "./EditModal";

import styles from "./index.module.css";

export type Group = {
  name: string;
  id: number;
  description: string;
  productCategories: Category[];
};

export type Option = {
  id: number;
  languageCode: string;
  name: string;
  optionSelect: [];
  order: number;
  productGroupId: number;
  type: string;
  optionOrder?: number;
};

export type Pagination = { take: number; skip: number; total: number };

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Language",
    dataIndex: "language",
    key: "language",
  },
  {
    title: "Order",
    dataIndex: "order",
    key: "order",
  },
  {
    title: "Product group",
    dataIndex: "productGroup",
    key: "productGroup",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: " ",
    dataIndex: "actions",
    key: "actions",
  },
];

const Options = () => {
  const [selectedOption, setSelectedOption] = useState<Option>();
  const [pagination, setPagination] = useState<Pagination>({ take: 10, skip: 0, total: 0 });
  const [selectedGroup, setSelectedGroup] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);

  const {
    loading: getOptionsLoading,
    data,
    refresh,
  } = useRequest(() => getGroupOptions(localCountry?.iso, selectedGroup, pagination), {
    ready: !!localCountry,
    refreshDeps: [pagination.skip, pagination.take, localCountry],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: (response) => {
      if (response.count) {
        setPagination((prev) => ({ ...prev, total: response.count }));
      }
    },
  });
  const { loading: getGroupsLoading, data: groups } = useRequest(() => getGroups(localCountry?.iso), {
    ready: !!localCountry,
    refreshDeps: [localCountry],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });
  const languages = localCountry?.languages;
  const { loading: deleteLoading, run: deleteHandler } = useRequest(deleteOption, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "option was deleted");
      refresh();
    },
  });

  const source = useMemo(
    () =>
      data?.options.map((el) => ({
        ...el,
        key: el.id,
        productGroup: <div>{groups?.find((group) => group.id === el.productGroupId)?.name || ""}</div>,
        language: el.languageCode,
        actions: (
          <div className={styles.buttonWrapper}>
            <Button
              style={{ marginRight: 20 }}
              type="primary"
              onClick={() => {
                setSelectedOption(el);
                setIsModalOpen(true);
              }}
            >
              Edit
            </Button>
            <Popconfirm
              title={`Delete ${el.name}`}
              description="Are you sure to delete this option?"
              onConfirm={() => deleteHandler(el.id, localCountry?.iso)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger loading={deleteLoading}>
                Delete
              </Button>
            </Popconfirm>
          </div>
        ),
      })) || [],
    [data, deleteHandler, deleteLoading, groups, localCountry],
  );
  const groupOptions = groups?.map((group) => ({ label: group.name, value: group.id })) || [];

  const onPaginationChange = (page: number) => {
    setPagination((prev) => ({ ...prev, skip: page * 10 - 10 }));
  };

  useEffect(() => {
    refresh();
  }, [refresh, selectedGroup]);

  return (
    <div className={styles.container}>
      <Divider orientation="left" className={styles.divider}>
        Options
      </Divider>
      <div className={styles.wrapper}>
        <Form.Item label="Group">
          <Select
            loading={getGroupsLoading}
            placeholder="group"
            style={{ width: "200px" }}
            options={[{ label: "all", value: "" }, ...groupOptions]}
            onChange={(value) => setSelectedGroup(value)}
          />
        </Form.Item>
      </div>
      <Table
        loading={getOptionsLoading}
        dataSource={source}
        columns={columns}
        className={styles.table}
        pagination={false}
      />
      {selectedOption ? (
        <EditOptionModal
          languages={languages}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          refresh={refresh}
          optionId={selectedOption.id}
        />
      ) : null}
      <div className={styles.pagination}>
        <Pagination
          total={pagination.total}
          current={pagination.skip / 10 + 1}
          onChange={onPaginationChange}
          defaultPageSize={10}
        />
      </div>
      <div className={styles.buttonContainer}>
        <NewOption groups={groups} refresh={refresh} languages={languages} />
      </div>
    </div>
  );
};

export default Options;
