import { useCallback, useContext, useMemo } from "react";
import { DebateDetails } from "../Debate.types";
import { Button } from "antd";
import { AdminContext } from "context/adminContext";
import { startDebateProcess } from "helpers/backend_helper";
import { FinishDebateForm } from "../FinishDebateForm/FinishDebateForm";

import styles from "../DetailsModal/Details.module.css";

interface IActionsProps {
  data: DebateDetails;
  refreshData: () => void;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Actions = ({ data, refreshData, setIsOpened }: IActionsProps) => {
  const { localCountry, accountData } = useContext(AdminContext);

  const handleStartProcess = useCallback(
    async (id) => {
      await startDebateProcess(id, localCountry?.iso);
      refreshData();
    },
    [localCountry, refreshData],
  );

  const actions = useMemo(() => {
    switch (data?.status) {
      case "WAIT":
        return (
          <Button type="primary" onClick={() => handleStartProcess(data.id)}>
            Start process
          </Button>
        );
      case "IN_PROGRESS":
        return accountData?.id === data?.arbitr ? (
          <FinishDebateForm
            debateId={data.id}
            refreshData={refreshData}
            setIsOpened={setIsOpened}
            insuranceDeposit={+data.rent.insuranceDeposit}
            insuranceType={data.rent.insuranceType}
          />
        ) : null;
      default:
        return null;
    }
  }, [data, refreshData, setIsOpened, handleStartProcess, accountData]);

  if (!data) return null;

  return actions ? <div className={styles.actionsFooter}>{actions}</div> : null;
};
