import { useContext, useState } from "react";
import { Button, Card, Form, Input, Modal, Select } from "antd";
import { useRequest } from "ahooks";
import { createOption } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import { Group } from "../../Groups";
import { AdminContext } from "context/adminContext";
import LanguageForm from "../LanguageNameForm";
import DynamicOptions from "../DynamicItems";
import type { Language } from "../../Languages";

import styles from "./index.module.css";

type Props = {
  refresh: () => void;
  groups: Group[];
  languages: Language[];
};

const options = [
  { label: "Numeric", value: "Numeric" },
  { label: "String", value: "String" },
  { label: "Select", value: "Select" },
  { label: "MultiSelect", value: "MultiSelect" },
];

const NewOption = ({ refresh, groups, languages }: Props) => {
  const [form] = Form.useForm();
  const [type, setType] = useState("Numeric");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);

  const { loading: createLoading, run: create } = useRequest(createOption, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "option was created");
      refresh();
      setIsCreateModalOpen(false);
      form.resetFields();
    },
  });

  const onFinish = (values) => {
    const type: string = values.type;
    const translations = languages?.map((language) => {
      const nameKey = `option name${language.code}`;
      return { name: values[nameKey], languageCode: language.code };
    });
    const groupData: any = {
      type,
      translations,
      productGroupId: values.group,
    };
    if (type === "Select" || type === "MultiSelect") {
      const select = translations;
      const selectValues = [];
      Object.entries(values.values).forEach((value: [string, string]) => {
        const language = languages?.find((lang) => value[0].endsWith(lang.code));
        const key = +value[0].split(" ").at(-2) - 1;
        if (!selectValues[key]) {
          selectValues[key] = { translations: [{ value: value[1], languageCode: language.code }] };
        } else {
          selectValues[key].translations.push({ value: value[1], languageCode: language.code });
        }
        groupData.select = { translations: select, values: selectValues.filter(Boolean) };
      });
    }
    if (type === "Numeric") {
      groupData.numericRange = {};
      groupData.numericRange.min = +values.min;
      groupData.numericRange.max = +values.max;
    }
    create(groupData, localCountry?.iso);
  };
  const groupOptions = groups?.map((group) => ({ label: group.name, value: group.id }));

  return (
    <>
      <Modal
        className={styles.modal}
        title="Create new option"
        open={isCreateModalOpen}
        footer={null}
        onCancel={() => setIsCreateModalOpen(false)}
      >
        <div className={styles.container}>
          <Form layout="vertical" onFinish={onFinish} form={form} style={{ maxWidth: "600px" }}>
            <Card size="small" title="Option name" className={styles.card}>
              {languages?.map((language) => <LanguageForm key={language.id} language={language} name="option name" />)}
            </Card>
            <Card size="small" title="Type" className={styles.card}>
              <Form.Item
                name="type"
                rules={[
                  {
                    required: true,
                    message: "type is required",
                  },
                ]}
              >
                <Select placeholder="type" options={options} onChange={setType} />
              </Form.Item>
            </Card>
            <Card size="small" title="Group" className={styles.card}>
              <Form.Item
                name="group"
                rules={[
                  {
                    required: true,
                    message: "group is required",
                  },
                ]}
              >
                <Select placeholder="group" options={groupOptions} />
              </Form.Item>
            </Card>
            {type === "Numeric" || type === "String" ? null : <DynamicOptions languages={languages} />}
            {type === "Numeric" ? (
              <Card size="small" title="Range" className={styles.card}>
                <Form.Item
                  name="min"
                  rules={[
                    {
                      required: true,
                      message: "min is required",
                    },
                  ]}
                >
                  <Input placeholder="min" type="number" />
                </Form.Item>
                <Form.Item
                  name="max"
                  rules={[
                    {
                      required: true,
                      message: "max is required",
                    },
                  ]}
                >
                  <Input placeholder="max" type="number" />
                </Form.Item>
              </Card>
            ) : null}
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={createLoading} style={{ marginTop: "10px" }}>
                Create
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Button style={{ marginRight: 20 }} type="primary" onClick={() => setIsCreateModalOpen(true)}>
        Create new option
      </Button>
    </>
  );
};

export default NewOption;
