import { useContext, useMemo, useState } from "react";
import { Button, Divider, Popconfirm, Table, Tag } from "antd";
import { useRequest } from "ahooks";
import { deleteGroup, deleteGroupFromCategory, getCategories, getGroups } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import NewGroup from "./NewGroup";
import EditGroupModal from "./EditModal";
import { getTreeItems, type Category } from "../Categories";
import { AdminContext } from "context/adminContext";
import AddCategoryModal from "./AddCategory";

import styles from "./index.module.css";

export type Group = {
  name: string;
  id: number;
  description: string;
  productCategories: Category[];
};

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: <div className={styles.renter}>Name</div>,
    dataIndex: "name",
    key: "name",
  },
  {
    title: <div className={styles.period}>Description</div>,
    dataIndex: "description",
    key: "description",
  },
  {
    title: <div className={styles.price}>Categories</div>,
    dataIndex: "categories",
    key: "categories",
  },
  {
    title: " ",
    dataIndex: "actions",
    key: "actions",
  },
];

const Groups = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<Group>(null);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);
  const {
    loading: getLoading,
    data,
    refresh,
  } = useRequest(() => getGroups(localCountry?.iso), {
    ready: !!localCountry,
    refreshDeps: [localCountry],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });
  const { loading: getCategoriesLoading, data: categories } = useRequest(() => getCategories(localCountry?.iso), {
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });
  const { loading: deleteLoading, run: deleteGroupHandler } = useRequest(deleteGroup, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "group was deleted");
      refresh();
    },
  });
  const { run: deleteFromCategory } = useRequest(deleteGroupFromCategory, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "group was deleted from category");
      refresh();
    },
  });
  const treeData = useMemo(() => (!categories ? [] : categories?.map((el) => getTreeItems(el))), [categories]);
  const source = useMemo(
    () =>
      data?.map((el, i) => ({
        ...el,
        key: i,
        categories: (
          <div>
            {el.productCategories?.map((pc, i) => (
              <Tag
                closable
                color="red"
                key={pc.name + i}
                onClose={() => deleteFromCategory(el.id, pc.id, localCountry?.iso)}
              >
                {pc.name}
              </Tag>
            ))}
          </div>
        ),
        actions: (
          <div className={styles.buttonWrapper}>
            <Button
              style={{ marginRight: 20 }}
              type="primary"
              onClick={() => {
                setSelectedGroup(el);
                setIsModalOpen(true);
              }}
            >
              Edit
            </Button>
            <Popconfirm
              title={`Delete ${el.name}`}
              description="Are you sure to delete this group?"
              onConfirm={() => deleteGroupHandler(el.id, localCountry.iso)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger loading={deleteLoading}>
                Delete
              </Button>
            </Popconfirm>
            <Button
              style={{ marginLeft: 20 }}
              type="primary"
              onClick={() => {
                setSelectedGroup(el);
                setIsAddModalOpen(true);
              }}
            >
              Add category
            </Button>
          </div>
        ),
      })) || [],
    [data, deleteGroupHandler, deleteFromCategory, deleteLoading, localCountry],
  );

  return (
    <div className={styles.container}>
      <Divider orientation="left" className={styles.divider}>
        Groups
      </Divider>
      <Table loading={getLoading} dataSource={source} columns={columns} className={styles.table} pagination={false} />
      <div className={styles.buttonContainer}>
        <Button type="primary" onClick={() => setIsCreateModalOpen(true)}>
          Add new group
        </Button>
      </div>
      <EditGroupModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        refresh={refresh}
        id={selectedGroup?.id}
      />
      <AddCategoryModal
        isModalOpen={isAddModalOpen}
        setIsModalOpen={setIsAddModalOpen}
        refresh={refresh}
        id={selectedGroup?.id}
        getCategoriesLoading={getCategoriesLoading}
        treeData={treeData}
      />
      <NewGroup
        refresh={refresh}
        treeData={treeData}
        getCategoriesLoading={getCategoriesLoading}
        setIsCreateModalOpen={setIsCreateModalOpen}
        isCreateModalOpen={isCreateModalOpen}
      />
    </div>
  );
};

export default Groups;
