import { SocketContext } from "context/socketContext";
import img2 from "assets/icon1.png";
import { Badge, Button, Divider, Spin } from "antd";
import { useContext, useState } from "react";
import { useRequest } from "ahooks";
import { getChats } from "helpers/backend_helper";
import { AdminContext } from "context/adminContext";
import { NotificationContext } from "context/notificationContext";
import Chat from "./Chat";

import styles from "./index.module.css";

const connections = [
  {
    name: "Elanor Pera",
    date: "July 5, 03:09 AM",
    picture: <img className={styles.picture} src={img2} width={46} height={46} />,
    avatar: <img className={styles.image} src={img2} width={56} height={56} />,
    hasMessage: true,
    addButton: true,
  },
];

const MainMessagesContent = () => {
  const { joinChatAdmin } = useContext(SocketContext);
  const { localCountry } = useContext(AdminContext);
  const [currentChat, setCurrentChat] = useState(null);
  const { openNotificationWithIcon } = useContext(NotificationContext);

  const { loading, data: chats } = useRequest(() => getChats(localCountry?.iso), {
    refreshDeps: [localCountry],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
  });

  return (
    <div className={styles.container}>
      <Divider orientation="left" className={styles.divider}>
        Messages
      </Divider>
      <Button onClick={() => joinChatAdmin("ID")}></Button>
      {loading ? (
        <Spin></Spin>
      ) : (
        <div className={styles.messenger}>
          <div className={styles.connections}>
            {connections.map((connect) => (
              <Button key={connect.name} className={styles.connectButton}>
                {connect?.avatar}
                <div className={styles.textWrapper}>
                  <div className={styles.textTitle}>
                    {connect.name}{" "}
                    {connect.hasMessage ? <Badge status="success" style={{ marginLeft: "5px" }} /> : null}
                  </div>
                  <div className={styles.supportText}>{connect.date}</div>
                </div>
                <div className={styles.pictureWrapper}>
                  {connect.addButton ? <Badge status="warning" /> : null}
                  {connect.picture}
                </div>
              </Button>
            ))}
            {chats?.chats.map((chat) => (
              <Button key={chat.id} className={styles.connectButton} onClick={() => setCurrentChat(chat)}>
                <div className={styles.textWrapper}>
                  <div className={styles.textTitle}>{chat.clientId}</div>
                  <div className={styles.supportText}>some date</div>
                </div>
                <div className={styles.pictureWrapper}>
                  <Badge status="warning" />
                </div>
              </Button>
            ))}
          </div>
          {/* <div className={styles.chatWrapper}>
            <div className={styles.chat}>
              <div className={styles.myMessages}>
                <div className={styles.messageDate}>July 5, 03:09 AM</div>
                <div className={styles.message}>my message</div>
              </div>
              <div className={styles.otherMessages}>
                <div className={styles.messageDate}>July 5, 03:09 AM</div>
                <div className={styles.message}>other message</div>
              </div>
              <div className={styles.myMessages}>
                <div className={styles.messageDate}>July 5, 03:09 AM</div>
                <div className={styles.message}>my message</div>
              </div>
              <div className={styles.otherMessages}>
                <div className={styles.messageDate}>July 5, 03:09 AM</div>
                <div className={styles.message}>other message</div>
              </div>
              <div className={styles.myMessages}>
                <div className={styles.messageDate}>July 5, 03:09 AM</div>
                <div className={styles.message}>my message</div>
              </div>
              <div className={styles.otherMessages}>
                <div className={styles.messageDate}>July 5, 03:09 AM</div>
                <div className={styles.message}>other message</div>
              </div>
            </div>
            <div className={styles.inputBox}>
              <Input className={styles.input} />
              <button className={styles.sendButton} />
            </div>
          </div> */}
          <Chat id={currentChat?.id} />
        </div>
      )}
    </div>
  );
};

export default MainMessagesContent;
