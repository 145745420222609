import { SocketContext } from "context/socketContext";
import { useContext } from "react";
import { useRequest } from "ahooks";
import { AdminContext } from "context/adminContext";
import { NotificationContext } from "context/notificationContext";
import { startSupportChat } from "helpers/backend_helper";

import { Chat } from "pages/Main/Components/Chat";

type Props = {
  id: string;
};

const AdminChat = ({ id }: Props) => {
  const { joinChatAdmin, sendMessageSupport } = useContext(SocketContext);
  const { localCountry, getChatHistory, getHistoryLoading, chats } = useContext(AdminContext);
  const { openNotificationWithIcon } = useContext(NotificationContext);

  console.log(id);
  const { loading } = useRequest(() => startSupportChat(localCountry?.iso, id), {
    ready: !!id,
    refreshDeps: [id],
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      joinChatAdmin(id);
      getChatHistory(id);
    },
  });

  return (
    <Chat
      chatId={id}
      sendSocketMessage={sendMessageSupport}
      loading={loading || getHistoryLoading}
      messages={chats?.supportChat.messages}
    />
  );
};

export default AdminChat;
